import Cookies from "js-cookie";

export const onClientEntry = () => {
  console.log("onClientEntry");
  const consentSet = Cookies.get("consentSet");
  const consentObjStr = Cookies.get("consentObj");
  const consentObj = consentObjStr ? JSON.parse(consentObjStr) : {};

  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=AW-932983784`;
  script.async = true;
  script.id = "Gtag_4537452543";
  document.head.appendChild(script);

  if (consentSet === "true") {
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      console.log("consent update");
      gtag("consent", "update", consentObj);
    };
  } else {
    console.log("consentSet !== true");
  }
};
